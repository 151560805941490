<template>
    <div>
        <van-popup class="popclass" closeable v-model="viewPayshow">
            <div class="title" v-if="payInfo.pay_type == 1">USDT收款</div>
            <div class="title" v-if="payInfo.pay_type == 2">支付宝收款</div>
            <div class="title" v-if="payInfo.pay_type == 3">银行卡收款</div>
            <div class="content">
                <div>姓名：{{ payInfo.username }}</div>
                <div v-if="payInfo.pay_type == 1"  @click="copyLink1(payInfo.wx_account)" id="tag1">地址：
                    <a href="javascript:;">{{ payInfo.wx_account }}</a>
                </div>
                <div v-if="payInfo.pay_type == 2" @click="copyLink2(payInfo.alipay_account)" id="tag2">支付宝账号:
                    <a href="javascript:;">
                        {{ payInfo.alipay_account }}
                    </a>
                </div>
                <div v-if="payInfo.pay_type < 3">
                    <div>收款二维码:</div>
                    <van-image width="180" height="180" :src="payInfo.ali_img" style="margin-top: 15px;"/>
                </div>
                <template v-if="payInfo.pay_type==3">
                    <div @click="copyLink3(payInfo.bank_card_no)" id="tag3">银行卡号:
                        <a href="javascript:;">
                            {{ payInfo.bank_card_no }}
                        </a>
                    </div>
                    <div>开户支行:{{ payInfo.bank_card_union_no }}</div>
                    <div>银行卡类型:{{ payInfo.bank_card_type }}</div>
                    <div>开户行:{{ payInfo.bank_card_open_bank }}</div>
                </template>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Clipboard from 'clipboard'
    import { Toast } from 'vant'
    export default {
        name: "Viewpay",
        data() {
            return {
                viewPayshow: false,
                userInfo: {},
                address: "",
                password: "",
                payInfo: {
                    id: 418,
                    uid: 80,
                    address: "",
                    alipay_account: "",
                    ali_img: "",
                    wx_account: "",
                    wx_img: "",
                    pay_type: 1,
                    create_time: "0000-00-00 00:00:00",
                    disable: 0,
                    is_delete: 0,
                    bank_card_no: "",
                    bank_card_union_no: "",
                    bank_card_type: "",
                    bank_card_open_bank: "",
                    username: "",
                },
                pay_type: "",
                oid: "",
            };
        },
        methods: {
            //复制
            copyLink1(content){
                var _this = this;
                let clipboard = new Clipboard('#tag1', {
                    text: function () {
                        return content;
                    }
                })
                clipboard.on('success', e => {
                    Toast('复制成功');
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    Toast('复制失败');
                    clipboard.destroy()
                })
            },
            copyLink2(content){
                var _this = this;
                let clipboard = new Clipboard('#tag2', {
                    text: function () {
                        return content;
                    }
                })
                clipboard.on('success', e => {
                    Toast('复制成功');
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    Toast('复制失败');
                    clipboard.destroy()
                })
            },
            copyLink3(content){
                var _this = this;
                let clipboard = new Clipboard('#tag3', {
                    text: function () {
                        return content;
                    }
                })
                clipboard.on('success', e => {
                    Toast('复制成功');
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    Toast('复制失败');
                    clipboard.destroy()
                })
            },
            preview(payInfo) {
                this.payInfo = payInfo;
                this.userInfo = sessionStorage.getItem("user")
                    ? JSON.parse(sessionStorage.getItem("user"))
                    : {};
                this.address = sessionStorage.getItem("address");
                this.viewPayshow = true;
                console.log(this.payInfo)
            },
        },
    };
</script>

<style lang="scss" scoped>
    .popclass {
        width: 640px;
        height: 900px;
        background: #0d1912;
        .title{
            font-size: 36px;
            text-align: center;
            font-weight: bold;
            height: 50px;
            line-height: 50px;
            color: #fff;
            margin-top: 30px;
        }
        div{
            color: #37C085;
        }
        .content {
            padding: 32px;
            color: white;
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
            line-height: 50px;
            word-break:break-word;
            a{
                color: #6FB4EB;
            }
        }
    }


</style>